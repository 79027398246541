
.homePageContainer{
  padding: 8px 0;
}
.wrapCard{
  width: calc(25% - 24px);
  flex-shrink: 0;
  margin: 12px;
  @media (max-width: 1023.9px){
      width: calc(50% - 24px);

  }
  @media (max-width: 767.9px){
    width: 100%;
    margin: 0;
  }
}
.cardsRow{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px 32px;
  //margin-bottom: 32px;

  @media(max-width: 1439.9px){

  }

  @media(max-width: 767.9px){
    margin: 0;
    display: block;
    .wrapCard {
      margin-bottom: 32px;
      display: block;
    }
  }
}


.chartWrapper {
  position: relative;
}

.analyticsAnchor {
  position: absolute;
  top: -104px;
}
