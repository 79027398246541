@import "src/common";

.wrapHeader {
  width: calc(100% - 248px);
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  margin-left: 248px;
  @include animationSidebar();
  @media (min-width: 768px) and (max-width: 1439.9px) {
    width: calc(100% - 80px);
    margin-left: 80px;
    transition: none;
  }
  @media (max-width: 767.9px) {
    margin-left: 0;
    width: 100%;
  }
}

.wrapHeaderCollapsed {
  margin-left: 80px;
  width: calc(100% - 80px);
  @include animationSidebar();
  @media (min-width: 768px) and (max-width: 1439.9px) {
    width: calc(100% - 80px);
    margin-left: 80px;
    transition: none;
  }
  @media (max-width: 767.9px) {
    width: 100%;
    margin-left: 0;
    transition: none;
  }
}

.headerContent {
  width: 100%;
  border-bottom: 1px solid $border;
  background-color: white;
}

.header {
  position: relative;
  margin: 0 auto;
  z-index: 100;
  padding: 16px 32px;
  display: flex;
  align-items: center;
  @media (max-width: 1439.9px) {
    padding: 16px 24px;
  }
  @media (max-width: 767.9px) {
    padding: 16px 15px;
  }
}

.menuIcon {
  margin-right: 32px;
  height: 24px;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1439.9px) {
    display: none;
  }
  @media (max-width: 767.9px) {
    display: block;
    margin-right: 16px;
  }

  &.menuIconSearchOpen {
    @media (max-width: 767.9px) and (min-width: 375px) {
      margin-right: 14px;
    }
  }
}

.search {
  width: 360px;
  margin-right: auto;
  transition: all 0.2s;
  @media (max-width: 1023.9px) {
    width: 320px;
  }
  @media (max-width: 767.9px) {
    display: none;
  }
}

.mobileSearch {
  transition: none;
  width: 48px;
  height: 48px;
  padding: 11px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #d9e1ff;
  background-color: #fbfcff;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    flex-shrink: 0;
  }

  display: none !important;
  @media (max-width: 767.9px) {
    display: flex !important;
  }
  @media (max-width: 767.9px) {
    margin-right: auto !important;
  }
}

.icon {
  height: 24px;
}

.mobileSearch.mobileSearchOpen {
  transition: all 0.2s;
  flex-grow: 1;
  padding: 0 24px;
  margin-right: 0;

  & svg {
    margin-left: 10px;
  }
}

.wrapBell {
  margin-right: 32px;
  @media (max-width: 1439.9px) {
    margin-right: 24px;
  }
}

.wrapIcon {
  width: 48px;
  height: 48px;
  padding: 11px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid $border;
  background-color: $inpBg;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767.9px) {
    margin-right: 23px;
  }

  &:hover {
    cursor: pointer;
  }
}

.dropdownPanel {
  background: white;
  border: 1px solid $border;
  box-sizing: border-box;
  border-radius: 10px;
  position: absolute;
  right: 0;
  top: calc(100% + 16px);
  z-index: 101;
  width: 264px;
  max-height: 80vh;
  overflow-y: auto;

  & .dropdownBlock:last-child {
    border-bottom: none;
  }

  @media (max-width: 767.9px) {
    right: 15px;
  }
  @media (max-width: 374.9px) {
    right: 0;
  }
}

.dropdownBlock {
  padding: 24px 0 0 0;
  border-bottom: 1px solid $border;
}

.dropdownItem {
  position: relative;
  padding: 0 0 24px;
  display: flex;
  align-items: center;
  .dropdownItemTextBlock {
    padding-left: 64px;
  }
}
.wallet_address {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  font-family: "Inter", sans-serif;
  color: $textSecondary;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dropdownItemWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button {
  &:hover {
    cursor: pointer;
    .text {
      color: rgba(0, 83, 241, 1);
    }
  }
}

.smallIcon {
  position: absolute;
  top: -24px;
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $textSecondary;
  font-family: "Inter", sans-serif;
  text-decoration: none;
}

.textLittle {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: $textSecondary;
  font-family: "Inter", sans-serif;
  text-decoration: none;
}

.textBalance {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 16px;
  color: $textSecondary;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.questionTooltip {
  margin-left: 10px;
}

.dropdownCoins {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  color: $text;
  word-break: break-all;
}

.addCard {
  &:hover {
    cursor: auto;
  }
}

.hidden {
  display: none;
}

.redNotice {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: $error;
  margin-top: 16px;
}

.tooltip {
  width: 200px;
  padding: 5px !important;
  font-size: 11px !important;
  line-height: 13px !important;
  color: #5a5d65 !important;
  -webkit-box-shadow: 2px 2px 4px 0px rgba(133, 133, 133, 0.2);
  -moz-box-shadow: 2px 2px 4px 0px rgba(133, 133, 133, 0.2);
  box-shadow: 2px 2px 4px 0px rgba(133, 133, 133, 0.2);
}
