.wrapper {
  padding: 80px 0;

  @media (min-width: 768px) {
    padding: 104px 0;
  }

  @media (min-width: 1440px) {
    padding: 148px 0;
  }
  @media (min-width: 1920px) {
    padding: 160px 0;
  }
}


.subtitle {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #6E7E9D;

  margin-bottom: 32px;

  @media (min-width: 768px) {
    margin-bottom: 64px;
  }
  @media (min-width: 1440px) {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 82px;
  }
}

.wrapperBullets {
  display: flex;
  margin-bottom: 80px;
  flex-wrap: wrap;
  row-gap: 40px;

  @media (min-width: 768px) {
    margin-bottom: 72px;
    row-gap: 48px;
  }
  @media (min-width: 1440px) {
    margin-bottom: 152px;
    flex-wrap: nowrap;
  }
}

.iconText {
  font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #1B2A47;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
  @media (min-width: 1440px) {
    font-size: 32px;
    line-height: 40px;
  }
}

.iconWrapper, .iconWrapper svg {
  width: 60px;
  height: 60px;

  @media (min-width: 768px) {
    width: 80px;
    height: 80px;
  }
}

.iconBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  width: 50%;
  @media (min-width: 1199px) {
    width: 25%;
  }
}

.secondaryTextWrapper {
  text-align: center;
}

.secondaryText {
  font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #1B2A47;
  max-width: 864px;
  width: 100%;
  margin: 0 auto 40px;

  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 32px;
    margin: 0 auto 32px;
  }
  @media (min-width: 1440px) {
    font-size: 32px;
    line-height: 40px;
    margin: 0 auto 56px;
  }

}

.buttonsBlock {
  display: flex;
  justify-content: center;
  column-gap: 40px;
  @media (max-width: 1199px) {
    width: 320px;
    row-gap: 32px;
    flex-direction: column;
    margin: auto;
  }
}



