.container{
  width: 96px;
  height: 24px;
  border-radius: 43px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}