.successModal {
  max-width: 480px;
}

.inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px 80px 58px 80px;
}

.text {
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  margin-top: 40px;
}
