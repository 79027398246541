@import "src/common";
.container{
  background: $cardBg;
  border: 1px solid $border;
  border-radius: 10px;
  padding: 9px 12px 9px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}
.left{
  display: flex;
  align-items: center;
}
.icon{
  margin-right: 16px;
  height: 24px;
}
.text{
  font-size: 18px;
  line-height: 24px;
  color: $textSecondary;
}
.link{
  color: $secondary;
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}
.close{
  &:hover{
    cursor: pointer;
  }
}