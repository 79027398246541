@import "src/common";

.container {
  position: relative;
  border-radius: 10px;
  padding: 5px;
}

.labelWrapper {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.effect {
  &blur {
    filter: blur(5px);
  }

  &grayscale {
    filter: grayscale(1);
  }
}