@import "../../../../common";

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftBlock {
  display: flex;
  column-gap: 80px;
  align-items: center;

  @media (max-width: 767.8px) {
    column-gap: 10px;
  }
}

.menu {
  display: flex;
  column-gap: 60px;
  row-gap: 60px;
  align-items: center;
}

.menuItem {
  color: $textBlack;
  text-decoration: none;
  white-space: nowrap;
}

.menuItem:hover {
  color: $secondary;
}

.mobileMenuButton {
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.mobileMenuButtonLine {
  width: 32px;
  height: 2px;
  background-color: $textBlack;
  display: block;
}

.rightBlock {
  display: flex;
  align-items: center;
}

.mobileMenuButton {
  margin-left: 40px;
}

.mobileMenuWrapper {
  background: linear-gradient(180deg, #f4f6fd 0%, #e9edfd 100%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
    align-items: center;
}

.mobileMenu2 {
  background: linear-gradient(180deg, #f4f6fd 0%, #e9edfd 100%);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.mobileMenu {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

.logo {
  z-index: 10;
}

.menuItemMobile {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: $textBlack;
  text-decoration: none;
}

.closeButton {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 40px;
  top: 40px;
}

.miniLogo {
  border: none;
  background-color: transparent;
  position: absolute;
  left: 40px;
  top: 40px;
}
