@import "src/common";

.wrapper {
  flex-wrap: wrap;
  background-color: #ffffff;
  padding: 160px 0;
  justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media(max-width: 1919px) {
    padding: 144px 0;
  }
  @media(max-width: 1439px) {
    padding: 104px 0;
  }
  @media(max-width: 1199px) {
    grid-template-columns: 1fr 1fr;
        padding: 104px 0;
  }
  @media(max-width: 767px) {
    grid-template-columns: 1fr;
    padding: 80px 0;
  }
}

.itemWrapper {
  margin-bottom: 48px;
  max-width: 360px;
  width: 100%;
  @media(max-width: 1439px) {
    max-width: 300px;
  }
  @media(max-width: 1199px) {
    max-width: none;
    text-align: center;
  }
}

.itemIcon {
  display: flex;
  justify-content: center;
  width: 80px;
  height: 80px;
  color: #0053f1;
  @media(max-width: 1199px) {
    margin: auto;
    width: 60px;
  }
}

.itemTitle {
  margin-bottom: 18px;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: $textBlack;
  font-family: 'Outfit', sans-serif;
  @media(max-width: 1200px) {
    font-size: 24px;
    line-height: 30px;
  }
}

.itemSubtitle {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: $subtitleText;
  @media(max-width: 1200px) {
    font-size: 16px;
  }
}

