body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.hidden {
  overflow: hidden !important;
}

body.hiddenError {
  overflow: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.slick-slide div {
  outline: none;
}

* {
  box-sizing: border-box;
}

.center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.majorPartnersIntegrationsSlider .slick-slider .slick-list {
  overflow: visible;
}

.reviewsSlider .nextArrow {
  right: 0;
  bottom: 14px;
  top: auto;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #1b2a47;
  display: flex;
  width: 121px;
  height: 22px;
  flex-wrap: nowrap;
  align-items: center;
}

@media (max-width: 1439px) {
  .reviewsSlider .nextArrow {
    bottom: 18px;
    width: 101px;
    height: 19px;
  }
}

.nextArrowText {
  margin-left: 16px;
}

.reviewsSlider .nextArrow:before {
  content: none !important;
}

.reviewsSlider .nextArrow:hover {
  color: #0053f1;
}

.decorLineWrapper {
  position: relative;
  height: 0;
  width: 64px;
  border-radius: 2px;
  border: 2px solid #6e7e9d;
  box-sizing: border-box;
}

.reviewsSlider .nextArrow:hover .decorLineWrapper {
  border: 2px solid #0053f1;
}

.decorLine {
  position: absolute;
  width: 100%;
  height: 2px;
}

.page-loading {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}


.textBlock {
  max-width: 1000px;
  width: 100%;
  margin: auto;
  padding-bottom: 128px;
  padding-top: 92px;

}

@media (max-width: 1919px) {
  .textBlock {
    max-width: 800px;
    padding-bottom: 112px;
    padding-top: 60px;
  }
}

@media (max-width: 1439px) {
  .textBlock {
    padding: 0 60px;
    padding-bottom: 72px;
    padding-top: 42px;
  }
}

@media (max-width: 1199px) {
  .textBlock {
    padding: 0 40px;
    padding-bottom: 72px;
    padding-top: 42px;
  }
}

@media (max-width: 767px) {
  .textBlock {
    padding: 0 20px;
    padding-bottom: 48px;
    padding-top: 30px;
  }
}


.container {
  max-width: 1920px;
  padding: 0 240px;
  width: 100%;
  margin: auto;

}

@media (max-width: 1919px) {
  .container {
    padding: 0 120px;
  }
}

@media (max-width: 1439px) {
  .container {
    padding: 0 60px;
  }
}

@media (max-width: 1199px) {
  .container {
    padding: 0 40px;
  }
}

@media (max-width: 767px) {
  .container {
    padding: 0 20px;
  }
}