@import url("https://fonts.googleapis.com/css2?family=Commissioner:wght@400;500&family=Inter:wght@300;400;500;600;700&display=swap&family=Outfit:wght@300;400;500;600;700&display=swap");

$primary: #1fcf90;
$primaryHover: #28ab7c;
$secondary: #0053f1;
$secondaryHover: #154db8;
$menuHover: #1c67f6;
$accent: #ff002e;
$accentHover: #cc3e36;
$text: #333333;
$textBlack: #1b2a47;
$subtitleText: #6e7e9d;
$white: #ffffff;
$textSecondary: #5a5d65;
$textTh: #9296a5;
$drag: #b4b9c6;
$grey: #a6b4ce;
$greyHover: #909cb5;
$lightGreyText: #6e7e9d;
$lightGreyText2: #adb7d9;
$darkGrayText: #1b2a47;
$bg: #e5e5e5;
$bgInput: #f4f6fd;
$bgInputHover: #e2e5f0;
$placeholder: #ccd2e3;
$menuItem: #ccd2e3;
$menuGroup: #84a1da;
$border: #d9e1ff;
$inpBg: #fbfcff;
$error: #e9493f;
$cardBg: #fafbfe;
$transparent: transparent;
$transparentHover: transparent;
$inputDisabled: #e8e9ea;
$sm: 767.9px;

$md: 768px;
$lg: 1440px;
$xl: 1920px;

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  background: #f4f6fd;
  font-family: "Inter", sans-serif;
}

html {
  scroll-behavior: smooth;
}

@mixin animationSidebar() {
  transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.pageTitleCommon {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: $textSecondary;
}
.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 767.9px) {
    display: block;
  }
}
.titleRow {
  @media (max-width: 767.9px) {
    margin-bottom: 32px;
  }
}
.headerRowCenter {
  align-items: center;
}

@mixin placeholder() {
  ::-webkit-input-placeholder {
    color: $placeholder;
  }

  :-moz-placeholder {
    color: $placeholder;
  }

  ::-moz-placeholder {
    color: $placeholder;
  }

  :-ms-input-placeholder {
    color: $placeholder;
  }

  ::-ms-input-placeholder {
    color: $placeholder;
  }

  ::placeholder {
    color: $placeholder;
  }
}

.labelInput {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: $textSecondary;
  margin-bottom: 8px;
}

.wrapper {
  position: relative;
}

.cursor {
  &:hover {
    cursor: pointer;
  }
}

.block {
  background: white;
  border: 1px solid $border;
  border-radius: 10px;
}

.blockHeader {
  padding: 24px;
  border-bottom: 1px solid $border;
}
.blockTitle {
  color: $textSecondary;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.blockContent {
  padding: 24px;
  & .blockItemRow:last-child {
    margin-bottom: 0;
  }
}

.headerBlockTabsTitleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $border;
  padding-left: 24px;
  padding-right: 24px;
  @media (max-width: 374.9px) {
    padding: 0 15px;
  }
}

.headerBlockTabs {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: $textSecondary;
  @media (max-width: 374.9px) {
    font-size: 16px;
    line-height: 22px;
  }
}

.tabs {
  display: flex;
  & .tab:last-child {
    margin-right: 0;
  }
}
.tab {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  width: 140px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -2.5px;
  border-bottom: 3px solid transparent;
  margin-right: 5px;
  color: $textSecondary;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 767.9px) {
    width: 80px;
    height: 60px;
    font-size: 14px;
    line-height: 20px;
  }
}
.tab.tabActive {
  color: $secondary;
  border-bottom: 3px solid $secondary;
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
