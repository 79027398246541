.wrapper {
  padding: 60px 0 0;
}

.header {
  background: linear-gradient(180deg, #F4F6FD 0%, #E9EDFD 100%);
}

.container {
  max-width: 1920px;
  padding: 0 240px;
  width: 100%;
  margin: auto;
  @media (max-width: 1919px) {
    padding: 0 120px;
  }

  @media (max-width: 1439px) {
    padding: 0 60px;
  }

  @media (max-width: 1199px) {
    padding: 0 40px;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }
}

.containerFluid {
  max-width: 100%;
}

.backgroundColorWhite {
  background-color: #ffffff;

}

.paddingBottom {
padding-bottom: 160px;
  @media (max-width: 1919px) {
    padding-bottom: 144px;
  }
  @media (max-width: 1439px) {
    padding-bottom: 104px;
  }
  @media (max-width: 1199px) {
    padding-bottom: 92px;
  }
  @media (max-width: 767px) {
    padding-bottom: 80px;
  }
}

.paddingBottomMin {
padding-bottom: 110px;
  @media (max-width: 1919px) {
    padding-bottom: 94px;
  }
  @media (max-width: 1439px) {
    padding-bottom: 54px;
  }
  @media (max-width: 1199px) {
    padding-bottom: 42px;
  }
  @media (max-width: 767px) {
    padding-bottom: 80px;
  }
}