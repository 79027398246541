@import "src/common";

.container{
  border-radius: 5px;
  border: 1px solid $border;
  background: white;
  padding: 16px 24px;
  width: 264px;
  height: 160px;
  position: relative;
  @media (max-width: 1439.9px) {
    width: 330px
  }
  @media (max-width: 767.9px) {
    width: 100%;
    max-width: 346px;
  }
}
.containerDisabled {
  &:hover{
    cursor: auto;
  }
    &::after{
      display: block;
      z-index: 1;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(244, 246, 253, 0.6);
    }
}
.badgeWrap{
  width: 96px;
  height: 24px;
  position: absolute;
  right: 24px;
  top: -12px;
  z-index: 2;
}
.iconBlock{
  height: 60px;
  padding-bottom: 10px;
}
.title{
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Inter', sans-serif;
  margin-bottom: 12px;
  color: $text;
}
.subtitle{
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  font-family: 'Inter', sans-serif;
  color: $textSecondary;
  @media (max-width: 1439.9px) {
    max-width: 220px;
  }
}

.onHover {
  transform: scale(1);
  transition: 0.3s transform ease-in-out;
  &:hover{
    cursor: pointer;
    transform: scale(1.1);
    transition: 0.3s transform ease-in-out;
  }
}

.label {
  margin: 0px 0px 10px 10px;
}

@keyframes highlight {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.highLight {
  position: absolute;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  left: -1.5px;
  top: -1.5px;
  outline: solid 3px #22c55e;
  animation-duration: 1.5s;
  animation-name: highlight;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}