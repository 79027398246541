@import "src/common";
.containerUpload{
  width: 308px;
  position: fixed;
  z-index: 10;
  bottom: 32px;
  right: 32px;
  background-color: white;
}
.topPart{
  background: #0053F1;
  border-radius: 10px 10px 0 0;
  padding: 12px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & *{
    color: #CCD2E3;
    fill: #CCD2E3;
    font-family: 'Inter', sans-serif;
  }
}
.topLeft{
  display: flex;
  align-items: center;
}
.topRight{
  display: flex;
  align-items: center;
}
.topText{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.percentageBlock{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 24px;
  background: #1A64F0;
  border-radius: 30px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-right: 16px;
}
.bottomPart{
  padding: 19px 25px 16px 25px;
  background-color: white;
}
.bottomRow{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottomLeft{
  display: flex;
  align-items: center;
}
.filesLeft{
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #5A5D65;
}
.bottomRight{
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #5A5D65;
}
.iconFile{
  margin-right: 16px;
  flex-shrink: 0;
}
.progress{
  margin-top: 8px;
  background: $bgInput;
  width: 100%;
  height: 8px;
  border-radius: 100px;
  overflow: hidden;
}
.innerProgress{
  background: $primary;
  height: 8px;
  width: 0;
}
.iconBlock{
  &:hover{
    cursor: pointer;
  }
}