@import "src/common";

.container {
  overflow: hidden;
  position: relative;
}

.wrapper {
  padding: 160px 144px;
  background: #F4F6FD;
  overflow: visible;

  @media (max-width: 1919px) {
    padding: 144px 120px 200px;
  }
  @media (max-width: 1439px) {
    padding: 104px 60px 160px;
  }
  @media (max-width: 767px) {
    padding: 80px 20px 116px;
  }
}

.title {
  font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  color: #1B2A47;
  margin-bottom: 80px;

  @media (max-width: 1919px) {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 64px;
  }

  @media (max-width: 1439px) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 48px;
  }
}

.image {
  width: 100%;
  //height: 56px;

  //@media (max-width: 1199px) {
  //  height: 48px;
  //}
  //
  //@media (max-width: 767px) {
  //  height: 40px;
  //}
}

.wrapperSlider {

  @media (max-width: 1023px) {
    width: 90%;
    overflow: visible;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.sliderItem {
  padding: 0 70px;
  height: 60px;

  @media (max-width: 1199px) {
    padding: 0 40px;
  }

  @media (max-width: 1023px) {
    padding: 0 100px;
  }

  @media (max-width: 767px) {
    padding: 0 50px;
  }

  @media (max-width: 532px) {
    padding: 0 20px;
  }
}

.cardItem {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageWrapper {
  width: 170px;
}

.wrapperCard {
  display: flex;
  justify-content: space-between;

  @media (max-width: 1023px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  @media (max-width: 533px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }
}