@import "src/common";

.pLanding {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6E7E9D;
  margin-bottom: 32px;
}

li {
  margin-bottom: 5px;
}

