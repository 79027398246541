@import "src/common";

.input{
  font-family: 'Inter', sans-serif;
  height: 48px;
  width: 100%;
  padding: 15px 45px 15px 65px;
  background-color: $inpBg;
  border-radius: 10px;
  border: 1px solid $border;
  box-shadow: none;
  outline: none;
}
.mobileInput{
  font-family: 'Inter', sans-serif;
  width: 100%;
  height: 30px;
  padding: 0;
  background-color: $inpBg;
  border-radius: 0;
  border: none;
  box-shadow: none;
  outline: none;
}
.mobileInputHidden{
  display: none;
}

.input::-webkit-input-placeholder, .mobileInput::-webkit-input-placeholder {
  color: $textSecondary;
}

.input::-moz-placeholder, .mobileInput::-moz-placeholder {
  color: $textSecondary;
}

.input:-moz-placeholder, .mobileInput:-moz-placeholder {
  color: $textSecondary;
}

.input:-ms-input-placeholder, .mobileInput:-ms-input-placeholder {
  color: $textSecondary;
}

.searchIcon{
  position: absolute;
  left: 16px;
  top: 12px;
  &:hover{
    cursor: pointer;
  }
  @media (max-width: 767.9px) {
    display: none;
  }
}
.searchIconClose{
  position: absolute;
  right: 16px;
  top: 12px;
  height: 24px;
  &:hover{
    cursor: pointer;
  }
}

.container{
  position: relative;
  width: 100%;
}