@import "src/common";

.secondaryValue {
  position: relative;
  font-weight: 600;
  font-size: 64px;
  line-height: 112%;
  text-align: center;
  text-transform: uppercase;
  color: $lightGreyText;
  @media (max-width: 1919px) {
    font-size: 48px;
  }
  @media (max-width: 1439px) {
    font-size: 32px;
  }

  @media (max-width: 767px) {
    font-size: 20px;
  }
}

.secondarySubtitle {
  font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: $lightGreyText2;

  @media (max-width: 1919px) {
    font-size: 20px;
  }
  @media (max-width: 1439px) {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
}

.countUp {
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
}

.countUpHide {
  opacity: 0;
  z-index: -1;
  white-space: nowrap;
}