@import "src/components/UI/Input/input.module";
@import "src/common";

.chartsContainer {
  background: white;
  border: 1px solid #d9e1ff;
  border-radius: 10px;
}

.chartWrap {
  padding: 0 4px 20px;
  background: white;
}

.inputWrap {
  width: 180px;
  margin-right: 16px;

  & input {
    text-align: center;
  }

  @media (max-width: 1439.9px) {
    width: 170px;
  }
  @media (max-width: 767.9px) {
    width: 130px;
    &:last-child {
      margin-right: 0;
    }
  }
  @media (max-width: 374.9px) {
    width: 110px;
  }
}

.inputChart {
  @media (max-width: 374.9px) {
    padding: 0 10px;
  }
}

.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0;
  padding: 24px;
  padding-bottom: 12px;
  width: 100%;

  @media (max-width: 374.9px) {
    padding: 15px;
  }
}

.inputsRow {
  display: flex;
}

.chartButton {
  color: white;
  border-radius: 5px;
  width: 48px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  border: none !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  font-family: "Inter", sans-serif;
  transition: background 0.2s;
  background-color: $primary;
  margin-right: 8px;
  cursor: pointer;
}

.buttons {
  display: flex;
  padding-top: 32px;

  & .chartButton:last-child {
    margin-right: 0;
  }
}

.chartButton {
  cursor: pointer;
}

.chartButton:hover {
  background: #28ab7c;
}

.chartStorageContainer {
  width: 100%;
  height: 500px;
  min-width: 100%;
}
